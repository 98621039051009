import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const IndexPage = ({ data }) => (
  <Layout>
    <div className="mw8-l center pl3 pl0-l mt4 mt5-l">
      <h1 className="text-h1">{data.datoCmsBrandPage.title}</h1>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    datoCmsSite {
      globalSeo {
        siteName
      }
    }
    datoCmsBrandPage {
      title
    }
  }
`
